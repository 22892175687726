var $ = jQuery.noConflict();
var toggleFilter;
var toggleSwitch = 0;
var mobile = false;
var mobileWidth = 767;
checkMobile();
var slickArray = ['#ourStylePost_ig', '.btcHolder', '.ourStyleBlockHolder'];
var reqFields = true;
var $container;
var cartAjax = null;

function toggleCart() {
  if (jQuery('#sevenCartShelf').hasClass('active')) {
    hideCart();
  } else {
    showCart();
  }
}

function showCart() {
  if (!jQuery('#sevenCartShelf').hasClass('active')) {
    jQuery('#sevenCartShelf').addClass('active');
    jQuery('#sevenCartShelfBackground').fadeIn();
  }
  bindQuantities();
}

function hideCart() {
  if (!jQuery('.productOverlay').is(':visible')) {
    jQuery('#sevenCartShelf').removeClass('active');
    jQuery('#sevenCartShelfBackground').fadeOut();
  }
}

function openCart() {
  jQuery('#sevenCartShelf').addClass('active');
  jQuery('#sevenCartShelfBackground').fadeIn();
}


function showNewItems() {

}

jQuery(document).ready(function() {
  jQuery('.preorder-warning img, button[func="close"]').click(function() {
    jQuery('.preorder-warning').removeClass('active');
    console.log('clcik');
    cartCheck = true;
    setupAddToCart();
    jQuery('.single_add_to_cart_button').prop('disabled', false);
  });

  jQuery('.clear-cart').click(function() {
    console.log('stuff');
    cartCheck = false;
    jQuery('.preorder-warning').removeClass('active');
    jQuery('.single_add_to_cart_button').prop('disabled', false);
    jQuery('.single_add_to_cart_button').click();
  });
  $container = jQuery(document);

  if (jQuery('.woocommerce-MyAccount-navigation-link--autoship-schedules').length) {
    //jQuery('.woocommerce-MyAccount-navigation-link--autoship-schedules a').html('Auto Replenish');
  }
  $container.on('seven_quantity_change', function(event, quantityInput) {
    // Is the widget-panel open?
    if (jQuery('#sevenCartShelf').hasClass('active') || jQuery('body').hasClass('woocommerce-checkout') || jQuery('body').hasClass('woocommerce-cart')) {
      quantityChanged(quantityInput);

    }

  });

  if (jQuery('.woocommerce-MyAccount-content #add_payment_method').length) {
    jQuery("#wc-wc_autoship_authorize_net-payment-token-new").prop('checked', true)
  }

  jQuery('.closeSevenShelf').click(function() {
    toggleCart();
  });
  if (jQuery('body').hasClass('woocommerce-checkout')) {
    jQuery('.loginFormTitle').click(function() {
      jQuery('.stepOneArrow').removeClass('active');
      jQuery('.stepOneArrow', this).addClass('active');
      var loginParent = jQuery(this).parent();
      if (!jQuery('form', loginParent).is(':visible')) {
        //jQuery('.checkoutUserInfo .hiddenForm').slideUp();
        jQuery('form', loginParent).slideDown();
      }
    });
    jQuery('.continueAsGuest').click(function() {
      jQuery('.colCover').fadeIn();
      jQuery('.col2Cover').fadeOut();
      jQuery('.not_logged_in .editUserInfo').show();
      /*
      	    	jQuery('form.login.hiddenForm').slideUp();
      	    	jQuery('form.register.hiddenForm').slideUp();
      */
      jQuery('.checkoutColumn').removeClass('activeCheckoutColumn');
      jQuery('.checkoutStep').removeClass('active');
      jQuery('.checkoutColumn_2').addClass('activeCheckoutColumn');
      jQuery('.checkoutStep_2').addClass('active');

    });


    jQuery('.checkoutNext, .checkoutStep_3, .col3Cover').click(function() {
      reqFields = true;
      jQuery('body').trigger('update_checkout');

      jQuery('.woocommerce-billing-fields .validate-required input.input-text').each(function() {
        jQuery(this).parent().css({
          'border': 'none',
          'background-color': 'white'
        });
        if ((!jQuery(this).val() || jQuery(this).val() == '') && jQuery(this).attr('type') != 'password') {
          reqFields = false;
          jQuery(this).parent().css({
            'border': '2px solid red',
            'background-color': '#ffe3e3'
          });
        }
      });
      if (reqFields) {
        //jQuery('body').unbind('updated_checkout');
        jQuery('.columnLoader').fadeIn();
        jQuery('.colCover').fadeIn();
        jQuery('body').on('updated_checkout', function() {
          if (reqFields) {
            jQuery('.col3Cover').fadeOut();
            jQuery('.checkoutColumn_2 .editDetails').show();
            jQuery('.checkoutColumn').removeClass('activeCheckoutColumn');
            jQuery('.checkoutStep').removeClass('active');
            jQuery('.checkoutColumn_3').addClass('activeCheckoutColumn');
            jQuery('.checkoutStep_3').addClass('active');
            jQuery('.columnLoader').fadeOut();
          }
          //jQuery('body').unbind('updated_checkout');
        });
      }
      console.log("running AJAX Copupon click");
      jQuery('.submitAJAXcoupon').on('click',function(){
        var couponCode = jQuery('.checkoutAJAXcoupon input').val();
        console.log("sending coupon in this place");
        addDiscount(couponCode);
      });


    });
    jQuery('.colCover, .checkoutStep').click(function() {
      clickStep(this);
    });

    jQuery('.not_logged_in .checkoutUserCover, .not_logged_in .editUserInfo').click(function() {
      jQuery('.colCover').fadeIn();
      jQuery('.checkoutUserCover').fadeOut();
      jQuery('.editDetails').hide();
      jQuery('.editUserInfo').hide();
    });
  }

  jQuery('.cartCountContainer').click(function() {
    toggleCart();
  });
  jQuery('.cartLink').mouseenter(function() {
    setTimeout(function() {
      jQuery('.cartLink').addClass('on');
    }, 300);
    //return false;
  });

  jQuery('.cartLink').mouseleave(function() {
    setTimeout(function() {
      jQuery('.cartLink').removeClass('on');
    }, 300);
    //return false;
  });

  jQuery('#sevenCartShelfBackground').click(function() {
    toggleCart();
  });

  if (jQuery("#ourInstagramBlock").length > 0) {
    getInstagram();
  }

  navToggle();
  jQuery(window).resize(function() {
    navToggle();
  });

  console.log("firing showProductDetails");

  showProductDetails();

  console.log("firing setupAddToCart");

  setupAddToCart();

  //This is for the nave (b)
  // jQuery('#productsMenu ul li').mouseenter(function() {
  //   var pSlug = jQuery(this).attr('slug');
  //   jQuery('.productsMenuImage.on').removeClass('on');
  //   jQuery('.productsMenuImage.pImg_' + pSlug).addClass('on');;
  // });
  // jQuery('#productsMenu ul li').mouseleave(function() {
  //   jQuery('.productsMenuImage.on').removeClass('on');
  // });
  //
  //
  // jQuery('nav#menu ul li.page_menu_item').click(function() {
  //   if (jQuery('.navHoverBox', this).length > 0 && !jQuery('.navHoverBox', this).hasClass('on') && mobile == true) {
  //     jQuery('.navHoverBox', this).removeClass('on');
  //     jQuery('.navHoverBox', this).addClass('on');
  //     return false;
  //   }
  // });

//mobile nav scripts
if($(window).width() < 768){
  console.log("in moblie");
$(".menu-item-has-children").click(function(){
  console.log("clicking");
if($(this).find(".sub-menu").length > 1){
  $(this).find(".sub-menu").first().slideDown();

}else if($(this).find(".sub-menu").length == 1 && !$(this).parent().parent().hasClass(".menu-item-has-children")){
  console.log($(this).find(".sub-menu").length);
  $(".sub-menu").slideUp('fast','swing');
  $(this).find(".sub-menu").first().slideDown('fast','swing');
}
});

}


  /*jQuery('nav#menu ul li.page_menu_item').mouseleave(function(){
  	if(jQuery('.navHoverBox', this).length > 0){
  		jQuery('.navHoverBox').removeClass('on');
  	}
  });*/

  //This is for full Bleed Images (c)
  jQuery(".innerContentInterior").imagesLoaded(function() {
    if (jQuery(".alignnone").length > 0) {

      var total = 1;
      jQuery(".alignnone").each(function() {
        jQuery(this).after("<div class='imgspacer spacer_" + total + "'></div><div class='clear'></div>");
        jQuery(".spacer_" + total).height(jQuery(this).height());
        var tops = jQuery(this).offset().top;
        jQuery(".bgh").append(jQuery(this));
        jQuery(this).css('top', tops + 'px');

        total++;

      });
    }



  });

  jQuery('.quizOption').click(function() {
    jQuery('.quizOption').removeClass('on');
    jQuery(this).addClass('on');

    var pID = jQuery(this).attr('pcatid');
    var pImage = jQuery('.quizProduct_' + pID).attr('fullimage');
    var activeContent = jQuery('.quizProduct_' + pID + ' .activeQuizProduct').html();
    jQuery('.activeQuizOverlay').empty().fadeIn();
    jQuery('.activeQuizOverlay').append(activeContent);
    jQuery('.activeQuizOverlay').append('<div class="quizOverlayImage"><img src="' + pImage + '"/></div>');
    jQuery('.activeQuizProductLeft').removeClass('on');
    jQuery('.activeQuizProductLeft').addClass('on');
    setTimeout(function() {
      jQuery('.quizOverlayImage').addClass('on');
    }, 200);

  });
  var searchSwitch = 0;
  jQuery('#menu_left input#searchsubmit').click(function() {
    if (searchSwitch == 0) {
      jQuery('.searchButton').addClass('on');
      jQuery('.closeSearch').click(function() {
        searchSwitch = 0;
        jQuery('.searchButton').removeClass('on');
      });
      searchSwitch = 1;
      return false;
    }

  });

  if (jQuery(".blog").length > 0) {
    jQuery(".blog").imagesLoaded(function() {
      jQuery('.blogposts').isotope({
        // options
        itemSelector: '.ourStylePost',
        layoutMode: 'packery',
        packery: {
          'gutter': '.blogGutter',
        }
      });

      let postsArr = {};

      jQuery('.ourStylePost').each(function() {
        jQuery(this).css('height', 'auto');
        // determine row of given element by its absolute position from top
        var row = 'row_' + jQuery(this).css('top');
        // store in row
        if (row in postsArr) {
          postsArr[row].push(jQuery(this));
        } else {
          postsArr[row] = [jQuery(this)];
        }
      });

      for (var key in postsArr) {
        let maxHeight = 0;
        for (var i = 0; i < postsArr[key].length; i++) {
          if (postsArr[key][i].height() > maxHeight) {
            maxHeight = postsArr[key][i].height();
          }
        }
        for (var i = 0; i < postsArr[key].length; i++) {
          postsArr[key][i].css('height', maxHeight + 'px');
        }
      }

      jQuery('.blogposts').isotope('layout');

    });
  }

  if (jQuery(".search").length > 0) {
    jQuery('.blogposts').isotope({
      // options
      itemSelector: '.ourStylePost',
      layoutMode: 'packery',
      packery: {
        'gutter': '.blogGutter',
      }
    });
  }

  if (jQuery(".cocktailSlickHolder").length > 0) {
    jQuery(".cocktailSlickHolder").slick({
      easing: 'easeInOutQuad',
      arrows: false,
      dots: true
    });
  }

  if (jQuery('body').hasClass('home') && jQuery(window).width() <= mobileWidth) {
    startSlick('.btcHolder');
    startSlick('.ourStyleBlockHolder');
  }

  jQuery('.loggedInLink').click(function() {
    jQuery('.signinLink .navHoverBox').css('display', 'block');
  });
  jQuery('.signinLink').mouseenter(function() {
    jQuery('.signinLink .navHoverBox').css('display', 'block');
  });
  jQuery('.signinLink').mouseleave(function() {
    jQuery('.signinLink .navHoverBox').css('display', 'none');
  });
  jQuery('.closeNavHoverBox').click(function() {
    jQuery('.signinLink .navHoverBox').css('display', 'none');
  });


  if (jQuery('body').hasClass('post-type-archive-product') && !mobile) {
    if (jQuery(window).scrollTop() > jQuery('.headerBlock').height()) {
      jQuery('.footerFamilyNav').slideDown();
      if (jQuery(window).width() > mobileWidth) {
        jQuery('.midSizeFilter').slideDown();
      }
    } else if (jQuery(window).scrollTop() > jQuery(document).height() - jQuery(window).height() - jQuery('#footer').height()) {
      jQuery('.footerFamilyNav').css('position', 'relative');
    }
  }

  /*if(jQuery('.loginButton').length > 0){
  	jQuery('.loginButton').click(function(){
  		loginBox();
  	});
  }*/

  //refferral stuff

  jQuery("#woocommerce_affiliate").on('change', function() {
    setReferral(jQuery(this).val());
  });

  jQuery(".gallery").each(function(i, t) {
    //  console.log(jQuery(this).attr('data-galId'));
    console.log(jQuery(this).addClass("gallery_" + i));
    jQuery("<img/>")
      .on('load', function() {
        console.log("image loaded correctly");
        jQuery(".gallery_" + i + " .galleryHolder").slick({
          centerMode: true,
          variableWidth: true,
          lazyLoad: 'ondemand',
          adaptiveHeight: true,
          dots: true,
          cssEase: 'ease-in-out',
          easing: 'easeInOutQuint',
          arrows: false,
          infinite: true,

        });

      })
      .on('error', function() {
        console.log("error loading image");
      })
      .attr("src", jQuery(this).find(".galleryImage:first img").attr('src'));


  });

  jQuery('.wp-block-gallery').each(function(i, t) {
    console.log(jQuery(this).addClass("gutenberg_gallery_" + i));
    jQuery(this).find('.blocks-gallery-item:first img').on('load', function() {
      console.log("image loaded correctly");
      jQuery(".gutenberg_gallery_" + i).not('.slick-initialized').slick({
        centerMode: true,
        variableWidth: true,
        lazyLoad: 'ondemand',
        adaptiveHeight: true,
        dots: true,
        cssEase: 'ease-in-out',
        easing: 'easeInOutQuint',
        arrows: false,
        infinite: true,

      });
    }).on('error', function() {
      console.log("error loading image");
    })
  });

  //close out errors if they are around
  $('body').on('click', '.messageContainerClose', function() {
    jQuery('.woocommerce-error').slideUp();
      jQuery('.woocommerce-message').slideUp();
      jQuery('.woocommerce-info').slideUp();

      });



  jQuery('.fixBilling').click(function(e) {
    jQuery('.checkoutStep_2').click();
    jQuery('.woocommerce-error').slideUp();
    e.preventDefault();
  });


  // ANCHOR PRODUCTS CLICK
  jQuery('.anchorToProducts').click(function() {
    var pSlug = jQuery(this).attr('slug');
    var famPos = jQuery('.familyHeader_' + pSlug).position().top;
    /*jQuery('html, body').animate({
			scrollTop: famPos - 80
    	}, 0);*/
    var extraScroll = 80;
    if (mobile) {
      extraScroll = 140;
    }
    jQuery('html, body').scrollTop(famPos - extraScroll);
  });



//for category pages
if($(".page-template-template-needs-results").length > 0){
  getOtherGroupOfProducts($(".needs-results-header").data("loadtype"));
  if(jQuery('.shopContent ul').length > 0){
    jQuery('.shopContent > ul').after('<div class="ulContent"></div>');
    jQuery('.shopContent > ul > li').each(function(i, ul){
      var liContent = '';
      jQuery('ul li', this).each(function(){
        liContent += jQuery(this).html()+'</br>';
        console.log(liContent);
      });
      jQuery('.ulContent').append('<div class="liContent liContent_'+i+'">'+liContent+'</div>');
      jQuery(this).click(function(){
          jQuery('.shopContent > ul > li').removeClass('active');
          jQuery(this).addClass('active');
        if(mobile){
          jQuery('.shopContent ul li ul').slideUp();
          jQuery('ul', this).slideDown();
        }else{
          jQuery('.liContent').slideUp();
          jQuery('.liContent_'+i).slideDown(400);
        }
      });
      if(i == 0){
        jQuery(this).addClass('active');
        jQuery(this).trigger('click');
      }
    });
  }


    var slickSlides = jQuery('.homeSlider').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      autoplay: true,
      fade: true,
      autoplaySpeed: 2000,
      speed:1000,
      easing: 'easeInOutQuad',
      responsive: [
      {
        breakpoint: mobileWidth,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows:true,
            }
        },
      ]
    });



    jQuery('.needs-results-cat, .needs-results-dropdown-cont a').click(function(e){
      if(!jQuery(this).parent().parent().hasClass('current')){
        console.log('new page');

      }else{
        e.preventDefault();
        var anchor = jQuery(this).attr('href');
        jQuery('html, body').animate({
          scrollTop: jQuery(anchor).offset().top
        }, 500, function(){
          window.location.hash = anchor.substr(1);
        });
      }


    });

var currentSlug = '';
jQuery(window).scroll(function(){
  var scrollTop = jQuery(window).scrollTop();
  console.log(scrollTop);

  jQuery('.familyProducts').each(function(index, value){
    if(scrollTop >= jQuery(this).position().top && scrollTop < jQuery(this).position().top + jQuery(this).height() - 20 ){
      console.log(jQuery(this).attr("id"));
      jQuery('.needs-results-cat[href="#' + jQuery(this).attr("id") + '"]').addClass('active');
    }else{
      jQuery('.needs-results-cat[href="#' + jQuery(this).attr("id") + '"]').removeClass('active');
    }
  });
});

jQuery('.needs-results-dropdown').click(function(){
  if(jQuery(window).width() > 767){
    jQuery('.needs-results-dropdown-cont').slideToggle().css('display','flex');
    jQuery(this).toggleClass('active');
  }
});

jQuery('.prod-cat').click(function(){
  if(jQuery(window).width() <= 767){
    jQuery(this).toggleClass('active');
    jQuery(this).siblings().find('.needs-results-dropdown-cont').slideUp();
    jQuery(this).siblings().removeClass('active');
    jQuery(this).find('.needs-results-dropdown-cont').slideToggle().css('display','flex');
  }
});


}

//end needs scripts

if(jQuery('.submitAJAXcoupon').length > 0){
  console.log(jQuery('.submitAJAXcoupon'));
  console.log("sending content");
  setTimeout(function(){
    console.log("DID THE TIMEOUT FOR content");

    jQuery('.submitAJAXcoupon').on('click',function(){
      var couponCode = jQuery('.checkoutAJAXcoupon input').val();
      console.log("sendgin COUPON");

      addDiscount(couponCode);
    });

  },2000);

}


jQuery('.newsletterSubmit').click(function() {
  var nlEmail = jQuery('input.newsletterInput', jQuery(this).parent()).val();
  var nlID = jQuery(this).attr('mcid');
  if (validateEmail(nlEmail)) {
    jQuery('input.newsletterInput', jQuery(this).parent()).css('border', 'none');
    $.ajax({
      url: ajaxurl,
      data: {
        'action': 'do_ajax',
        'fn': 'klaviyoSubscribe',
        'email': nlEmail,
      },
      //dataType: 'json',
      success: function(data) {
        //console.log(data);
        var nlJson = $.parseJSON(data);
        var nlmessage = '';
        var nlTitle = '';
        //console.log(nlJson);
        if (nlJson.title == 'Member Exists') {
          nlmessage = "IT LOOKS LIKE YOU'RE ALREADY SIGNED UP!";
          nlTitle = 'WHOOPS!';
        } else if (nlJson.status = 'subscribed') {
          nlmessage = "You’ll be first to know when new products are hitting shelves.";
          nlTitle = 'YOU GOT IT, BEAUTIFUL.';
        } else if (nlJson.title == "Invalid Resource") {
          nlmessage = "IT LOOKS LIKE SOMETHING'S NOT RIGHT!";
          nlTitle = 'WHOOPS!';
        }
        jQuery('.sevenOverlayContent').empty();
        jQuery('.sevenOverlayContent').append('<div class="newsletterPopup"><div class="closeOverlay"><img src="' + templateurl + '/images/x.png"/></div><h2>' + nlTitle + '</h2><h3 style="font-weight:300">' + nlmessage + '</h3></div>');
        jQuery('.sevenOverlay').fadeIn();
        jQuery('.closeOverlay, .sevenOverlay').click(function() {
          jQuery('.sevenOverlay').fadeOut();
        });
      },
      complete: function() {},
      error: function(errorThrown) {
        console.log(errorThrown);
      }
    });
  } else {
    jQuery('input.newsletterInput', jQuery(this).parent()).css('border', '2px solid red');
  }
});






//skin variation
if(jQuery('.variation-row select').length > 0){
  var selectInstance = new Select({
      el: document.querySelector('.variation-row select'),
      className: 'select-theme-default select-variation-'
  });
}


//For press galleries
if(jQuery('.pressHolder').length > 0){
if(jQuery(window).width()>768){
  jQuery('.pressHolder').each(function(){
var p = jQuery(this).isotope({
  // options
  itemSelector: '.pressImage',
  layoutMode: 'masonry',
  percentPosition: true
});

p.imagesLoaded().progress( function() {
  p.isotope('layout');
});

});

}

}


//shipping violator

if($(".violator").length > 0){
localStorage.currentviolator = $(".violator").data('id');
console.log("this is the ID");
console.log(localStorage.currentviolator);
if(localStorage.violatorid != localStorage.currentviolator){
  localStorage.setItem("violatorcount",0);
  localStorage.violatorid = localStorage.currentviolator
}

  console.log(localStorage.violatorcount);
  if(localStorage.violatorcount){
  localStorage.violatorcount =  Number(localStorage.violatorcount) + 1;
  }else{
    localStorage.violatorcount = 1;
  }
  if(Number(localStorage.violatorcount) < 4){
setTimeout(function(){
  $(".violator").addClass("active");
},3000)
setTimeout(function(){
  $(".violator").removeClass("active");
},13000)
}

}else{
  //wipe the cookie
  localStorage.setItem("violatorcount",0);

}



});

//END DOCUMENT.READY



function clickStep(stepEl) {
  //jQuery('.loginFormTitle').click();
  jQuery("html, body").animate({
    scrollTop: 0
  }, "fast");
  var colNum = jQuery(stepEl).attr('colnum');
  if (colNum == '3' || (colNum == "1" && jQuery('body').hasClass('logged-in'))) {} else {

    jQuery('.checkoutColumn').removeClass('activeCheckoutColumn');
    jQuery('.checkoutStep').removeClass('active');
    jQuery('.checkoutColumn_' + colNum).addClass('activeCheckoutColumn');
    jQuery('.checkoutStep_' + colNum).addClass('active');
    jQuery('.editDetails').show();
    jQuery('.checkoutStep_' + colNum + ' .editDetails').hide();
    jQuery('.colCover').fadeIn();
    jQuery('.col' + colNum + 'Cover').fadeOut();
    jQuery('.checkoutStep_' + colNum + ' .editDetails').hide();
  }
}

var maxProducts = 6;

function bindQuantities() {
  if (jQuery('.quantityBox', $container).length) {
    var tempVal;
    $container.off('focus.qty').on('focus.qty', 'input.qty', function() {
      var $this = jQuery(this);
      tempVal = parseFloat($this.val());
      $this.removeAttr('value');
      jQuery('.quantityAlert').remove();
    });
    $container.off('focusout.qty').on('focusout.qty', 'input.qty', function() {
      var $this = jQuery(this),
        currentVal = parseFloat($this.val()),
        max = parseFloat($this.attr('max')),
        min = parseFloat($this.attr('min'));
      if (maxProducts) {
        max = maxProducts;
      }
      if (!currentVal || currentVal === '' || currentVal === 'NaN') {
        currentVal = tempVal;
        $this.val(currentVal);
        return;
      }
      if (max === '' || max === 'NaN') max = '';
      if (min === '' || min === 'NaN') min = 0;
      if (max && currentVal > max) {
        $this.val(max);
        $this.parent().append('<div class="quantityAlert">This product has a limit of ' + max + ' per order.</div>');
        setTimeout(function() {
          jQuery('.quantityAlert').fadeOut();
        }, 3000);
      } else if (min && currentVal < min) {
        $this.val(min);
        $this.parent().append('<div class="quantityAlert">This product has a minimum of ' + min + ' per order.</div>');
        setTimeout(function() {
          jQuery('.quantityAlert').fadeOut();
        }, 3000);
      } else {

      }
      triggerQuantityChange($this);
    });

    $container.off('click.qty').on('click.qty', '.inc_button, .dec_button', function() {
      // Get elements and values
      var $this = jQuery(this),
        $qty = $this.closest('.quantityBox').find('.qty'),
        currentVal = parseFloat($qty.val()),
        max = parseFloat($qty.attr('max')),
        min = parseFloat($qty.attr('min')),
        step = $qty.attr('step');
      if (maxProducts) {
        max = maxProducts;
      }
      // Format values
      if (!currentVal || currentVal === '' || currentVal === 'NaN') currentVal = 0;
      if (max === '' || max === 'NaN') max = '';
      if (min === '' || min === 'NaN') min = 0;
      if (step === 'any' || step === '' || step === undefined || parseFloat(step) === 'NaN') step = 1;

      // Change the value
      if ($this.hasClass('inc_button')) {
        if (max && (max == currentVal || currentVal > max)) {
          $qty.val(max);
        } else {
          $qty.val(currentVal + parseFloat(step));
          triggerQuantityChange($qty);
        }
      } else {
        if (min && (min == currentVal || currentVal < min)) {
          $qty.val(min);
        } else if (currentVal > 0) {
          $qty.val(currentVal - parseFloat(step));
          triggerQuantityChange($qty);
        }
      }
    });
  }
  $container.off('click.remove').on('click.remove', '.remove', function() {
    var product_key = jQuery(this).data("cart-item-key");
    jQuery(this).closest('li').addClass('active');
    jQuery('.mini_cart_item_' + product_key + ' .mini_cart_item_cover').fadeIn('fast');
    jQuery.ajax({
      type: 'POST',
      dataType: 'json',
      url: ajaxurl,
      data: {
        action: "product_remove",
        product_key: product_key
      },
      success: function(data) {
        updateFragments(data);
        jQuery(this).closest('li').removeClass('active');
      }
    });
    return false;
  });
}

function triggerQuantityChange($qty) {
  $qty.trigger('change');
  // Trigger custom event
  $container.trigger('seven_quantity_change', $qty);
}

function addDiscount(code) {
  $('<div class="couponLoader" style="position:absolute; height:30px; width:30px; right:18%; top:7px"><img style="height:100%;width:100%;" src="https://7haircare.com/wp-content/themes/seven/images/loader.gif"></div>').appendTo('.AJAXcouponHolder');
$('.AJAXcouponHolder').css('position','relative');
  $.ajax({
    url: ajaxurl,
    data: {
      'action': 'do_ajax',
      'fn': 'add_discount',
      'discountcode': code,
    },
    //dataType: 'json',
    success: function(data) {
      if (data === '1') {
        jQuery('body').trigger('update_checkout');
      } else if (data === 2) {
        jQuery('.couponError').html("You've already applied a coupon!").slideDown();
        jQuery('.couponLoader').remove();
      } else {
        console.log(data);
        jQuery('.couponError').html("Oops, this coupon is invalid.").slideDown();
        jQuery('.couponLoader').remove();

      }
    },
    complete: function() {

    },
    error: function(errorThrown) {
      console.log(errorThrown);
    }
  });
}



function showProductDetails() {
  jQuery('.productDetails').unbind('click');
  jQuery('.productDetails').click(function() {

    window.location.href = jQuery(this).attr('producturl');
    var pid = jQuery(this).attr('productid');
    var famCol = jQuery(this).attr('famcol');
    var pImage = jQuery(this).attr('productthumb');
    var purl = jQuery(this).attr('productURL');
    if (jQuery(window).width() < 7680) {
      window.location.href = purl;
    } else {
      jQuery('body').append('<div class="productOverlay"><div class="productOverlayBackground"></div></div>');
      jQuery('.productOverlay').animate({
        'opacity': 1
      }, 200);
      $.ajax({
        url: ajaxurl,
        data: {
          'action': 'do_ajax',
          'fn': 'product_info',
          'pid': pid,
        },
        //dataType: 'json',
        success: function(data) {
          //data = eval(data);
          jQuery('.productOverlay').append(data);
          jQuery('.productOverlayRight').prepend('<img src="' + pImage + '"/>');
          jQuery('.productOverlayContent').animate({
            'opacity': 1
          }, 200);
          jQuery('.closeProductOverlay, .productOverlayBackground').click(function() {
            closeProductOverlay();
          });
        },
        complete: function() {
          setupAddToCart();
        },
        error: function(errorThrown) {
          console.log(errorThrown);
        }
      });
    }
  });
}

var cartCheck = true;

function setupAddToCart() {

  /* when product quantity changes, update quantity attribute on add-to-cart button */

  /*
      jQuery('.remove').unbind('click');
  	jQuery('.remove').click(function(){
  		var product_id = jQuery(this).attr("data-product_id");
  		jQuery('.mini_cart_item_'+product_id+' .mini_cart_item_cover').fadeIn('fast');
  		jQuery.ajax({
  			type: 'POST',
  			dataType: 'json',
  			url: ajaxurl,
  			data: { action: "product_remove",
  				product_id: product_id
              },
              success: function(data){
  				//console.log(data);
  				updateFragments(data);
              }
          });
  		return false;
  	});
  */

  $container.off('click', '.single_add_to_cart_button').on('click', '.single_add_to_cart_button', function(e) {
    var $thisButton = jQuery(this);
    $thisButton.addClass('loading');
    // Make sure the add-to-cart button isn't disabled
    if ($thisButton.is('.disabled')) {
      console.log('Add-to-cart- button is disabled');
      return;
    }

    // Only allow simple and variable product types
    if ($thisButton.hasClass('simple_add_to_cart_button') || $thisButton.hasClass('variable_add_to_cart_button')) {
      e.preventDefault();

      // Set button disabled state
      $thisButton.attr('disabled', 'disabled');

      var $productForm = $thisButton.closest('form');

      if (!$productForm.length) {
        return;
      }
      var data = {
        product_id: $productForm.find("input[name*='add-to-cart']").val(),
        //product_variation_id:	$productForm.find("input[name*='variation_id']").val(),
        //cart_num_items:			$productForm.find("input[name*='cart_num_items']").val(),
        //update_page: 			$productForm.find("input[name*='update_page']").val(),
        product_variation_data: $productForm.serialize()
      };



      // PREORER CHECKS
      // if cart is empty, add product to cart.
      // if cart has preorder product, tell user to checkout before adding other products.
      // if cart is not empty and user is adding a preorder product, ask if user wants to clear current cart or to cancel.

      if (cartCheck && jQuery('.menuCartCount').html() != 0) {
        console.log('ajaxing');
        jQuery.ajax({
          url: ajaxurl,
          data: {
            'action': 'do_ajax',
            'fn': 'preorder_prelim',
            'product': data,
          },
          //dataType: 'json',
          success: function(new_data) {
            if (new_data) {
              jQuery('.preorder-warning').removeClass('active pre-cart pre-prod');
              var preorder_obj = JSON.parse(new_data);


              if (preorder_obj['preorder_cart']) {
                jQuery('.preorder-warning').addClass('active pre-cart');
                cartCheck = false;
              } else {
                if (preorder_obj['preorder_prod']) {
                  jQuery('.preorder-warning').addClass('active pre-prod');
                } else {
                  $container.trigger('adding_to_cart', [$thisButton, data, false]);
                  closeProductOverlay();
                  // Submit product form via Ajax
                  submitForm($thisButton, data);
                  cartCheck = true;

                }
              }
              $thisButton.removeClass('loading');

            } else {
              console.log('nothing returnedddd');
            }
          },
          complete: function() {},
          error: function(errorThrown) {
            console.log(errorThrown);
          }
        });

      } else {
        $thisButton.removeClass('loading');
        console.log('no ajax, add to cart');
        // Trigger "adding_to_cart" event
        $container.trigger('adding_to_cart', [$thisButton, data, false]);
        closeProductOverlay();
        // Submit product form via Ajax
        submitForm($thisButton, data);
        cartCheck = true;
      }




    }
  });
  bindQuantities();
}

function submitForm($thisButton, data) {
  console.log("submitting products");
  var self = this;

  if (!data.product_id) {
    console.log('(Error): No product id found');
    return;
  }

  var ajaxAddUrl = 'add-to-cart=' + data.product_id + '&seven-ajax-add-to-cart=1';
  var selectedFreq = jQuery("select[name='wc_autoship_frequency'] option:selected").val();
  if (selectedFreq && selectedFreq != '') {
    ajaxAddUrl += '&wc_autoship_frequency=' + selectedFreq;
  }
  var atcUrl = wc_add_to_cart_params.wc_ajax_url.toString().replace('wc-ajax=%%endpoint%%', ajaxAddUrl);
  console.log(data.product_variation_data);
  console.log(atcUrl);

  showCart();
  // Submit product form via Ajax
  $.ajax({
    type: 'POST',
    url: atcUrl,
    data: data.product_variation_data,
    dataType: 'html',
    cache: false,
    headers: {
      'cache-control': 'no-cache'
    },
    error: function(XMLHttpRequest, textStatus, errorThrown) {
      console.log(errorThrown);
    },
    success: function(response) {
console.log(response);
      var $response = jQuery("<div>" + response + "</div>"), // Wrap the returned HTML string so we can get the replacement elements
        //$shopNotices = $response.find('#nm-shop-notices-wrap'), // Shop notices
        //hasError = ($shopNotices.find('.woocommerce-error').length) ? true : false, // Is there an error notice?
        cartHash = ''; // Note: Add the cart-hash to an element (data attribute) in the redirect template?

      // Get replacement elements/values
      // Note: Using ".prop('outerHTML')" to convert the jQuery objects/elements into strings (since they are passed to the "added_to_cart" event below)
      var fragments = {
        '.menuCartCount': $response.find('.menuCartCount').prop('outerHTML'), // Header menu cart count
        '.cart_list': $response.find('.cart_list').prop('outerHTML'),
        '.seven_cart_subtotal': $response.find('.seven_cart_subtotal').prop('outerHTML')
      };



      // Trigger "added_to_cart" event
      // Note: The "fragments" object is passed to make sure the various shop/cart fragments are updated
      $container.trigger('added_to_cart', [fragments, cartHash, false]);

      // Remove button disabled state
      $thisButton.removeAttr('disabled');
      /*
      			if (hasError) {
      				setTimeout(function() {
      					// Hide cart panel and overlay
      					jQuery('#nm-widget-panel-overlay').trigger('click');

                          if (self.isShop) {
                              // Smooth-scroll to shop-top
                              self.shopScrollToTop();
                          }
      				}, 500);
      			}
      */
      // Replace cart/shop fragments
      //console.log("this is resonsep");
      //console.log($response);
      cartReplaceFragments(fragments);
      $response.empty();
      if(jQuery(".buttons").length < 1){
        jQuery(".cart_list").after($('<p class="buttons"><a href="'+siteurl+'/checkout/" class="greyBackgroundButton button checkout wc-forward">Checkout</a></p>'));
      }

    },
    complete: function(){
        refreshRecommendedProducts();
        refreshShippingTotal();
    }
  });
}

function refreshShippingTotal(){
  console.log("RF Carts");
  var price = $(".seven_cart_subtotal").find("bdi").text();
  var newprice = parseInt(price.slice(1));
  var freeshippingPrice = 45;
  console.log(newprice);
if(newprice < freeshippingPrice){
  var pricemin = freeshippingPrice - newprice;
  var text = "You're only $"+pricemin+" dollars away from free shipping!";
  $(".shipping-reminder").text(text);
}else{
  $(".shipping-reminder").text("You've qualified for Free Shipping!");
}



}
function cartReplaceFragments(fragments) {
  var $fragment;
  //console.log(fragments);
  $.each(fragments, function(selector, fragment) {
    //console.log("this is fragment "+fragment);
    //console.log("this is selector "+selector);

    $fragment = jQuery(fragment);
    if ($fragment.length) {
      jQuery(selector).replaceWith(fragment);
    }
  });

}

function closeProductOverlay() {
  jQuery('.productOverlay').fadeOut(200);
  setTimeout(function() {
    jQuery('.productOverlay').remove();
  }, 200);
}

function loginBox() {
  jQuery('.sevenOverlayContent').empty();
  var loginPopup = jQuery('#loginContainer').html();
  jQuery('.sevenOverlayContent').append(loginPopup);
  jQuery('.sevenOverlay').fadeIn();
  jQuery('.closeOverlay').click(function() {
    jQuery('.sevenOverlay').fadeOut();
  });
}

function navToggle() {
  console.log("toggling Nav function called");

  if (jQuery(window).width() < 767) {
    jQuery('.navToggle').unbind("click");
    console.log("toggling Nav after disable");

    jQuery('.navToggle').click(function() {
      console.log("toggle");
      if (jQuery('.headerNavigation').hasClass('on')) {
        jQuery('.headerNavigation').removeClass('on');
        jQuery('.navToggle').removeClass('on');
        jQuery('body').css('overflow', 'auto');
        jQuery('.headerNavigation').css('overflow-y', 'scroll');
        jQuery('body').css('height', 'auto');
      } else {
        jQuery('.headerNavigation').addClass('on');
        jQuery('.navToggle').addClass('on');
        jQuery('.headerNavigation').css('overflow-y', 'scroll');
        jQuery('body').css('overflow', 'hidden');
        jQuery('body').css('height', '100vh');
      }
    });

    jQuery('.navToggleBox').each(function() {
      if (jQuery(this).attr('id') != 'featuredPostMenu') {
        jQuery(this).hide();
      }
    });

    jQuery('.hoverToggleItem').click(function() {
      if (!jQuery(this).hasClass('on')) {
        var navslug = jQuery(this).attr('navslug');
        jQuery('.navToggleBox').each(function() {
          if (jQuery(this).attr('id') != 'featuredPostMenu') {
            jQuery(this).slideUp();
          }
        });
        jQuery('.hoverToggleItem').removeClass('on');
        jQuery('.hti_' + navslug).addClass('on');
        jQuery('.navHover_' + navslug).slideDown();
      }
    });
  } else {
    jQuery('.navToggle').unbind('click');
    jQuery('.navToggleBox').show();
    jQuery('.hoverToggleItem').unbind('click');
  }
}

function checkMobile() {
  if (jQuery(window).width() <= mobileWidth) {
    mobile = true;
  } else {
    mobile = false;
  }
}

function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}


jQuery(window).resize(function() {
  checkMobile();
  if (jQuery(".alignnone").length > 0) {
    var total = 1;
    jQuery(".alignone").each(function() {
      jQuery(".spacer_" + total).height(jQuery(this).height());
      total++;

    });
  }
  if (jQuery(".blog").length > 0) {
    if (jQuery(window).width() > 767) {
      jQuery('.ourStylePost').each(function() {
        jQuery(this).css('height', '500px');
      });

      jQuery('.blogposts').isotope('layout');

      let postsArr = {};

      jQuery('.ourStylePost').each(function() {
        jQuery(this).css('height', 'auto');
        // determine row of given element by its absolute position from top
        var row = 'row_' + jQuery(this).css('top');
        // store in row
        if (row in postsArr) {
          postsArr[row].push(jQuery(this));
        } else {
          postsArr[row] = [jQuery(this)];
        }
      });

      for (var key in postsArr) {
        let maxHeight = 0;
        for (var i = 0; i < postsArr[key].length; i++) {
          if (postsArr[key][i].height() > maxHeight) {
            maxHeight = postsArr[key][i].height();
          }
        }
        for (var i = 0; i < postsArr[key].length; i++) {
          postsArr[key][i].css('height', maxHeight + 'px');
        }
      }

      jQuery('.blogposts').isotope('layout');
    } else if (jQuery(window).width() < 767) {
      jQuery('.ourStylePost').each(function() {
        jQuery(this).css('height', 'auto');
      });
    }
  }

  $.each(slickArray, function(i, slickitem) {
    if (jQuery(window).width() <= mobileWidth && jQuery(slickitem).length > 0) {
      if (!jQuery(slickitem).hasClass('slick-initialized')) {
        startSlick(slickitem);
      }
    } else if (jQuery(window).width() > mobileWidth && jQuery(slickitem).length > 0) {
      if (jQuery(slickitem).hasClass('slick-initialized')) {
        jQuery(slickitem).slick('unslick');
      }
    }
  });

});

function startSlick(slickHolder) {
  if (jQuery(slickHolder).length > 0 && jQuery(window).width() <= mobileWidth) {

    jQuery(slickHolder).slick({
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      centerMode: true,
      variableWidth: true,
      arrows: false,
    });
  }
}


function getOtherGroupOfProducts(fType) {
  $.ajax({
    url: ajaxurl,
    data: {
      'action': 'do_ajax',
      'fn': 'filter_products',
      'ftype': fType,
      //'ftype': filterType,
    },
    //dataType: 'json',
    success: function(data) {
      data = eval(data);
      replaceProducts(data, fType);
    },
    complete: function() {},
    error: function(errorThrown) {
      console.log(errorThrown);
    }
  });
}

function updateTotals() {
  $.ajax({
    url: ajaxurl,
    data: {
      'action': 'do_ajax',
      'fn': 'update_totals',
    },
    //dataType: 'json',
    success: function(data) {
      var newdata = jQuery.parseJSON(data);
      updateMiniCart(newdata);
    },
    complete: function() {},
    error: function(errorThrown) {
      console.log(errorThrown);
    }
  });
}

function updateMiniCart(data) {
  if (data.cart_count > 0) {
    if (!jQuery('.miniCartHasItems').hasClass('active')) {
      jQuery('.cartSlideOut').removeClass('active');
      jQuery('.miniCartHasItems').addClass('active');
    }
    var itemLabel = 'items';
    if (data.cart_count == 1) {
      itemLabel = 'item';
    }
    jQuery('.miniDetailsItems').html(data.cart_count + ' ' + itemLabel);
    jQuery('.miniDetailsTotal').html(data.cart_total);
  } else {
    if (!jQuery('.miniCartNoItems').hasClass('active')) {
      jQuery('.cartSlideOut').removeClass('active');
      jQuery('.miniCartNoItems').addClass('active');
    }
  }
  jQuery('.menuCartCount').html(data.cart_count);
}

function setGetParameter(paramName, paramValue) {
  var url = window.location.href;
  var hash = location.hash;
  url = url.replace(hash, '');
  if (url.indexOf(paramName + "=") >= 0) {
    var prefix = url.substring(0, url.indexOf(paramName));
    var suffix = url.substring(url.indexOf(paramName));
    suffix = suffix.substring(suffix.indexOf("=") + 1);
    suffix = (suffix.indexOf("&") >= 0) ? suffix.substring(suffix.indexOf("&")) : "";
    url = prefix + paramName + "=" + paramValue + suffix;
  } else {
    if (url.indexOf("?") < 0)
      url += "?" + paramName + "=" + paramValue;
    else
      url += "&" + paramName + "=" + paramValue;
  }
  window.history.pushState({
    "html": paramValue,
    "pageTitle": paramValue
  }, "", url + hash);
  //window.location.href = url + hash;
}

function filterProducts(filterType) {
  if (filterType == 'product_cat') {
    setGetParameter('filter', 'needs');
  } else {
    setGetParameter('filter', 'collection');
  }
  var animateSpeed = 100;
  var waitTime = jQuery('.headerProduct.active').length * animateSpeed;
  jQuery('html, body').animate({
    scrollTop: 0
  }, 300);

  //if(jQuery('.footerFamilyNav').is(':visible')){
  jQuery('.footerFamilyNav').slideUp(200);
  setTimeout(function() {
    jQuery('.footerFamily.active').removeClass('active').hide();
    jQuery('.footerFamily.hpType_' + filterType).addClass('active').show();
  }, 200);
  //}

  jQuery(jQuery('.headerProduct.active').get().reverse()).each(function(p, product) {
    setTimeout(function() {
      jQuery(product).removeClass('active');
    }, p * animateSpeed);
  });

  jQuery('#allProductsContainer').animate({
    'opacity': 0
  }, 300).slideUp(300);

  setTimeout(function() {
    jQuery('.familyProducts.active').removeClass('active').hide();
    jQuery('.familyProducts.hpType_' + filterType).addClass('active').show();
    jQuery('#allProductsContainer').slideDown(300).animate({
      'opacity': 1
    }, 300);
  }, 400);

  setTimeout(function() {
    jQuery('.headerProduct').each(function() {
      if (!jQuery(this).hasClass('hpType_' + filterType)) {
        jQuery(this).hide();
      } else {
        jQuery(this).css('display', 'inline-block');
      }
    });
    jQuery('.headerProduct.hpType_' + filterType).each(function(p, prod) {
      setTimeout(function() {
        setTimeout(function() {
          jQuery(prod).addClass('active');
        }, 300);

      }, p * animateSpeed);
    });
    setTimeout(function() {
      jQuery('.filterBox').removeClass('paused');
      toggleSwitch = 0;
    }, animateSpeed * jQuery('headerProduct.hpType_' + filterType));

  }, waitTime);
}

function quantityChanged($quantityInput) {
  if (cartAjax) {
    cartAjax.abort(); // Abort current Ajax request
  }

  jQuery($quantityInput).closest('li').addClass('active');

  var data = {
    action: 'do_ajax',
    fn: 'update_quantity'
  };
  data[jQuery($quantityInput).attr('name')] = jQuery($quantityInput).val();
  cartAjax = $.ajax({
    type: 'POST',
    url: ajaxurl,
    data: data,
    dataType: 'json',
    complete: function(response) {
      cartAjax = null; // Reset Ajax state

      var json = response.responseJSON;
      if (json && json.status === '1') {
        //cartReplaceFragments(json.fragments); // Replace cart/shop fragments
        updateFragments(json)
        // if(jQuery('body').hasClass('woocommerce-checkout')){
        //   jQuery('body').trigger('update_checkout');
        // }
      }

      // Hide any visible thumbnail loaders
      jQuery($quantityInput).closest('li').removeClass('active');
    }
  });
}

function updateQty(key, qty, id) {
  updatingCartItem(id);
  jQuery('.orderProductQuantity[key="' + key + '"] input').val(qty);
  $.ajax({
    url: ajaxurl,
    data: {
      'action': 'do_ajax',
      'fn': 'update_quantity',
      'pik': key,
      'pq': qty,
    },
    type: 'POST',
    success: function(data) {
      //console.log(data);
      //updateCartFragment();
      updateFragments(data);
    },
    complete: function() {},
    error: function(errorThrown) {
      console.log(errorThrown);
    }
  });
}

function updatingCartItem(id) {
  //console.log(jQuery('.mini_cart_item_'+id));
  jQuery('.mini_cart_item_' + id + ' .mini_cart_item_cover').fadeIn('fast');
}

function updateFragments(data) {
  console.log("update Fragments");
  if (data && data.fragments) {
    $.each(data.fragments, function(key, value) {
      jQuery(key).replaceWith(value);
    });
    //if ( supports_html5_storage() ) {
    //	sessionStorage.setItem( 'wc_fragments', JSON.stringify( data.fragments ) );
    //	sessionStorage.setItem( 'wc_cart_hash', data.cart_hash );
    //}
    if (jQuery('body').hasClass('woocommerce-checkout')) {
      jQuery('body').trigger('update_checkout');

    } else {
      jQuery('body').trigger('wc_fragments_refreshed');
    }
    updateTotals();
  }
}

function updateCartFragment() {
  $fragment_refresh = {
    url: wc_cart_fragments_params.ajax_url,
    type: 'POST',
    data: {
      action: 'woocommerce_get_refreshed_fragments'
    },
    success: function(data) {
      if (data && data.fragments) {
        $.each(data.fragments, function(key, value) {
          jQuery(key).replaceWith(value);
        });
        /*if ( supports_html5_storage() ) {
        	sessionStorage.setItem( 'wc_fragments', JSON.stringify( data.fragments ) );
        	sessionStorage.setItem( 'wc_cart_hash', data.cart_hash );
        }*/
        if (jQuery('body').hasClass('woocommerce-checkout')) {
          jQuery('body').trigger('update_checkout');
        } else {
          jQuery('body').trigger('wc_fragments_refreshed');
        }


        updateTotals();
      }
    }
  };

  //Always perform fragment refresh
  jQuery.ajax($fragment_refresh);
}

function supports_html5_storage() {
  try {
    return 'localStorage' in window && window['localStorage'] !== null;
  } catch (e) {
    return false;
  }
}

function replaceProducts(data, fType) {
  var animateSpeed = 100;
  //jQuery('.headerProduct').remove();
  var percWidth = 100 / data.length;
  $.each(data, function(i, term) {

    // MAKE TERM BOXES FOR HEADER
    var termBox = '<div class="headerProduct anchorToProducts hpType_' + fType + '" slug="' + term.slug + '" style="display:none;"><div class="productsHeaderImage pImg_' + term.slug + '"><img src="' + term.image + '"/></div><div class="pFamInfo">';
    if (fType == 'product_family') {
      if ('child' in term) { // CUBICA KENTE CRAZINESS...........
        termBox += '<div class="compoundTitle">';
        // ADD THE MAIN ONE FIRST
        termBox += '<div class="compoundTitleItem"><h5 class="pFamFont" style="color:' + term.color + '">' + term.name + '<span class="pfTM" style="color:' + term.color + ';top:-6px;">&reg;</span></h5>';
        if ('tagline' in term) {
          termBox += '<p style="color:' + term.color + '">' + term.tagline + '</p>';
        }
        // CLOSE MAIN ONE
        termBox += '</div><div class="compoundTitle">';
        termBox += '<div class="compoundTitleItem"><h5 class="pFamFont" style="color:' + term.color + '">' + term.child.name + '<span class="pfTM" style="color:' + term.color + ';top:-6px;">&reg;</span></h5>';
        if ('tagline' in term.child) {
          termBox += '<p style="color:' + term.color + '">' + term.child.tagline + '</p>';
        }
        // CLOSE CHILD
        termBox += '</div>'


        termBox += '</div>';
      } else {
        termBox += '<h5 class="pFamFont" style="color:' + term.color + '">' + term.name + '<span class="pfTM" style="color:' + term.color + ';top:-6px;">&reg;</span></h5><div class="clear"></div>';
        if ('tagline' in term) {
          termBox += '<p style="color:' + term.color + '">' + term.tagline + '</p>';
        }
      }
      //termBox += '<h5 style="color:'+term.color+'">'+term.name+'</h5><p style="color:'+term.color+'">'+term.tagline+'</p>';
    } else {
      termBox += '<p>' + term.name + '</p>';
    }
    termBox += '</div></div>';
    // END TERM BOXES FOR HEADER

    // MAKE PRODUCT AREA
    var familyProductsBox = '<div class="familyProducts familyProducts_' + term.slug + ' hpType_' + fType + '" slug="' + term.slug + '" style="display:none"><a name="' + term.slug + '"></a><div class="familyHeader familyHeader_' + term.slug + '">' + term.famImg;
    if ('child' in term) {
      familyProductsBox += '<a name="' + term.slug + '"></a><div class="familyHeaderInfo" style="background-color:' + term.color + '"><h5 class="whiteBG" style="color:' + term.color + '">' + term.subhead + '</h5><h2>' + term.child.name + '</h2>';
      if ('tagline' in term.child) {
        familyProductsBox += '<h4>' + term.child.tagline + '</h4>';
      }
      familyProductsBox += term.child.desc;
      familyProductsBox += '</div>'; // CLOSE familyHeaderInfo
    }
    familyProductsBox += '<div class="familyHeaderInfo" style="background-color:' + term.color + '"><h5 class="whiteBG" style="color:' + term.color + '">' + term.subhead + '</h5><h2>' + term.name + '</h2>';

    if ('tagline' in term) {
      familyProductsBox += '<h4>' + term.tagline + '</h4>';
    }
    familyProductsBox += term.desc;
    familyProductsBox += '</div>'; // CLOSE familyHeaderInfo


    familyProductsBox += '<div class="clear"></div></div><div class="familyProductsContainer">'; // CLOSE familyHeader

    var productWidth = '25%';
    var bgWidth = '100%';
    if (typeof term.products != 'undefined') {
      if ((term.products.length % 3 == 0 && term.products.length % 4 != 0) || term.products.length % 5 == 0) {
        productWidth = '33%';
        bgWidth = '70%';
      }
    }

    $.each(term.products, function(p, prod) {
      var famProd = '<div class="product p_' + prod.slug + '" style="width:' + productWidth + '"><div class="singleProductBG" style="width: ' + bgWidth + '; background-color: ' + prod.color + '"></div><div class="productContent"><a href="' + prod.link + '"><div class="productImage">' + prod.image + '</div><div class="productTitle"><span class="pName">' + prod.title + '</span><span class="pNeedName">' + prod.cat + '</span><p><span class="price">' + prod.price + '</span></p></div></a><div class="productHover"><div class="productHoverOptions">' + prod.hover + '</div></div></div></div>';
      familyProductsBox += famProd;
    });
    familyProductsBox += '<div class="clear"></div></div>';
    familyProductsBox += '</div>'; // CLOSE familyProducts



    // END PRODUCT AREA
    jQuery('#productsHolder.productsHeader').append(jQuery(termBox));
    //jQuery('#allProductsContainer').append(jQuery(familyProductsBox));

    jQuery('.filterBox').removeClass('off');
    toggleSwitch = 0;

    $.each(data, function(i, term) {

    });


  });

  jQuery(jQuery(data).get().reverse()).each(function(i, term) {
    var footerProdBox = '<div class="footerFamily anchorToProducts hpType_' + fType + ' hpType_' + fType + ' active" slug="' + term.slug + '" style="display:none;">';

    if ('child' in term) { // CUBICA KENTE CRAZINESS...........
      footerProdBox += '<div class="compoundTitle">';
      // ADD THE MAIN ONE FIRST
      footerProdBox += '<div class="compoundTitleItem"><h5 class="pFamFont" style="color:' + term.color + '">' + term.name + '<span class="pfTM" style="color:' + term.color + ';top:-6px;">&reg;</span></h5>';
      if ('tagline' in term) {
        footerProdBox += '<p style="color:' + term.color + '">' + term.tagline + '</p>';
      }
      // CLOSE MAIN ONE
      footerProdBox += '</div><div class="compoundTitle">';
      footerProdBox += '<div class="compoundTitleItem"><h5 class="pFamFont" style="color:' + term.color + '">' + term.child.name + '<span class="pfTM" style="color:' + term.color + ';top:-6px;">&reg;</span></h5>';
      if ('tagline' in term.child) {
        footerProdBox += '<p style="color:' + term.color + '">' + term.child.tagline + '</p>';
      }
      // CLOSE CHILD
      footerProdBox += '</div>'


      footerProdBox += '</div>';
    } else {
      footerProdBox += '<h5 class="pFamFont" style="color:' + term.color + '">' + term.name + '<span class="pfTM" style="color:' + term.color + ';top:-6px;">&reg;</span></h5><div class="clear"></div>';
      if ('tagline' in term) {
        footerProdBox += '<p style="color:' + term.color + '">' + term.tagline + '</p>';
      }
    }
    footerProdBox += '</div>' // CLOSE footerProdBox
    jQuery('.footerFamilyTable').prepend(jQuery(footerProdBox));
  });

  // FILTER OPTION CLICK

  jQuery('.filterOption').click(function() {
    var fType = jQuery(this).attr('filter');
    if (toggleFilter != fType && toggleSwitch == 0) {
      toggleFilter = fType;
      toggleSwitch = 1;
      jQuery('.filterBox').addClass('paused');
      jQuery('.filterOption').removeClass('activeSwitch');
      jQuery('.filterOption.fOption_' + fType).addClass('activeSwitch');
      filterProducts(fType);
    }
  });

  //THIS NEEDS TO BE FIXED!!
  showProductDetails();
}




jQuery(window).scroll(function() {

  if (jQuery('body').hasClass('post-type-archive-product') && !mobile) {
    if (jQuery(window).scrollTop() > jQuery('.headerBlock').height() && !jQuery('.footerFamilyNav').is(':visible')) {
      jQuery('.footerFamilyNav').slideDown();
      if (jQuery(window).width() > mobileWidth) {
        jQuery('.midSizeFilter').slideDown();
      }
    } else if (jQuery(window).scrollTop() <= jQuery('.headerBlock').height() && jQuery('.footerFamilyNav').is(':visible')) {
      jQuery('.footerFamilyNav').slideUp();
      if (jQuery(window).width() > mobileWidth) {
        jQuery('.midSizeFilter').slideUp();
      }
    } else if (jQuery(window).scrollTop() > jQuery(document).height() - jQuery(window).height() - jQuery('#footer').height()) {
      jQuery('.footerFamilyNav').css('position', 'relative');
    }

    if (jQuery(window).scrollTop() < jQuery(document).height() - jQuery(window).height() - jQuery('#footer').height() && jQuery('.footerFamilyNav').css('position') == 'relative') {
      jQuery('.footerFamilyNav').css('position', 'fixed');
    }
  };

  if (jQuery('.relatedProductsFooter').length > 0) {
    if (jQuery(window).scrollTop() < jQuery(document).height() - jQuery(window).height() - jQuery('#footer').height() + jQuery('.relatedProductsFooter').outerHeight() - 10 && jQuery('.relatedProductsFooter').css('position') == 'relative') {
      jQuery('.relatedProductsFooter').css('position', 'fixed');
    }
    if (jQuery(window).scrollTop() > jQuery(document).height() - jQuery(window).height() - jQuery('#footer').height() + jQuery('.relatedProductsFooter').outerHeight() - 10 && jQuery('.relatedProductsFooter').css('position') == 'fixed') {
      jQuery('.relatedProductsFooter').css('position', 'relative');
    }
  }

  if (jQuery(window).width() > 900) {
    if (jQuery(window).scrollTop() > 80) {
      jQuery("#site-title img").css('opacity', 1);
    } else {
      jQuery("#site-title img").css('opacity', 0);
    }
  }

});



function getInstagram() {
  startSlick('#ourStylePost_ig');
  var urli = "https://api.instagram.com/v1/users/self/media/recent/?access_token=489444653.b886df1.b69995d2cc5e4e7891df1eaba4ee0885&count=20";
  $.ajax({
    method: "GET",
    url: urli,
    dataType: "jsonp",
    jsonp: "callback",
    jsonpCallback: "jsonpcallback",
    success: function(data) {
      //var jsonEval = eval("("+data+")");
      $.each(data.data, function(i, item) {
        var itemText;
        var jsonObject = {};
        var hoverObject = {};
        if (item.type == "image") {
          var date = new Date(parseInt(item.created_time) * 1000);
          var month = (date.getMonth() + 1);
          var day = date.getDate();
          if (day < 10) {
            day = 0 + "" + day;
          }
          if (month < 10) {
            month = 0 + "" + month;
          }
          var year = date.getFullYear();
          if (item.caption) {
            hoverObject['content'] = item.caption.text;
          }
          jsonObject['link'] = item.link;
          jsonObject['image'] = item.images.standard_resolution.url;
          jsonObject['date'] = year + "" + month + "" + day;
          jsonObject['type'] = 'instagram';
          jsonObject['realdate'] = date;
          jsonObject['hover'] = hoverObject;
          var newDate = new Date();
          var dateDifference = (newDate - date) / (1000 * 60 * 60 * 24);
          dateDifference = Math.round(dateDifference);
          var daysAgo;
          if (dateDifference > 1 && dateDifference <= 199) {
            daysAgo = dateDifference + " " + "days ago";
          } else if (dateDifference == 0) {
            daysAgo = "today";
          } else if (dateDifference == 1) {
            daysAgo = dateDifference + " " + "day ago";
          } else if (dateDifference > 199) {
            daysAgo = dateDifference + " " + "day ago";
          }
          jQuery('#ourStylePost_ig').append('<div class="ourInstaPostContent" style="background-image:url(&#39' + item.images.standard_resolution.url + '&#39);"><a target="_blank" href="' + item.link + '"></a></div>');
        }
        if (item.type == "video") {}
      });

    },
    complete: function() {}
  });
}


function updateQuick() {

  updateCartFragment();
  //openCart();
  //updateTotals();

}

function refreshRecommendedProducts(){
  var cartItems = [];
  $(".cart_list li").each(function(i,v){
    cartItems.push($(this).data('pid'));
  });
  console.log(cartItems);
  $.ajax({
    url: ajaxurl,
    data: {
      'action': 'do_ajax',
      'fn': 'get_recommended_products',
      'cart' : cartItems
    },
    //dataType: 'json',
    success: function(data) {
      console.log("got the new products!");
      $(".orderProducts").slideUp('fast',function(){
        $(".orderProducts").html(data);
        $(".orderProducts").slideDown('fast');
      })
    },
    complete: function() {},
    error: function(errorThrown) {
      console.log(errorThrown);
    }
  });

}



function setReferral(ref) {
  var ref_cookie = $.cookie('affwp_ref');
  var visit_cookie = $.cookie('affwp_ref_visit_id');
  var campaign_cookie = $.cookie('affwp_campaign');
  var campaign = affwp_get_query_vars()['campaign'];
  $.removeCookie('affwp_ref');
  affwp_track_visit(ref, campaign);
}


function affwp_get_query_vars() {
  var vars = [],
    hash;
  var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
  for (var i = 0; i < hashes.length; i++) {
    hash = hashes[i].split('=');
    vars.push(hash[0]);

    var key = typeof hash[1] == 'undefined' ? 0 : 1;

    // Remove fragment identifiers
    var n = hash[key].indexOf('#');
    hash[key] = hash[key].substring(0, n != -1 ? n : hash[key].length);
    vars[hash[0]] = hash[key];
  }
  return vars;
}

function affwp_track_visit(affiliate_id, url_campaign) {
  // Set the cookie and expire it after 24 hours
  $.cookie('affwp_ref', affiliate_id, {
    expires: AFFWP.expiration,
    path: '/'
  });
  // Fire an ajax request to log the hit
  $.ajax({
    type: "POST",
    data: {
      action: 'affwp_track_visit',
      affiliate: affiliate_id,
      campaign: url_campaign,
      url: document.URL,
      referrer: document.referrer
    },
    url: affwp_scripts.ajaxurl,
    success: function(response) {
      $.cookie('affwp_ref_visit_id', response, {
        expires: AFFWP.expiration,
        path: '/'
      });
      $.cookie('affwp_campaign', url_campaign, {
        expires: AFFWP.expiration,
        path: '/'
      });
    }

  }).fail(function(response) {
    if (window.console && window.console.log) {
      console.log(response);
    }
  });

}

function getDataOfAffiliates() {
  $.ajax({
    url: ajaxurl,
    data: {
      'action': 'do_ajax',
      'fn': 'get_salons'
    },
    //dataType: 'json',
    success: function(data) {
      json = eval(data);
    },
    complete: function() {},
    error: function(errorThrown) {
      console.log(errorThrown);
    }
  });
}

function getNearbyAffiliates(currentLocation) {
  //console.log(currentLocation);
  var cLatLong;
  var geocoder = new google.maps.Geocoder();
  geocoder.geocode({
    'address': currentLocation
  }, function(results, status) {
    currentLocationTitle = results[0].formatted_address;
    if (status == google.maps.GeocoderStatus.OK) {
      lat = results[0].geometry.location.lat();
      lng = results[0].geometry.location.lng();
      cLatLong = new google.maps.LatLng(lat, lng);
      getListOfAffiliates(cLatLong);
    }
  });
}

function getListOfAffiliates(cLatLong) {
  var regSalons = [];
  var totalMarkers = 0;
  $.each(json, function(i, val) {
    var s = new google.maps.LatLng(parseFloat(val.Lat[0]), parseFloat(val.Long[0]));
    var distanceInkm = google.maps.geometry.spherical.computeDistanceBetween(cLatLong, s) / 1000;
    if (distanceInkm < 100) {
      if (val[1] != null) {
        regSalons.push(val);
        //addMarker('regular',s,val);
      }
      totalMarkers++;
    }
  });

  regSalons.sort(compare);
  jQuery('#woocommerce_affiliate').html('');
  jQuery('#woocommerce_affiliate').append('<option value="0">Select Your Salon</option>');

  if (regSalons.length == 0) {
    jQuery('#woocommerce_affiliate').html('<option value="0">Sorry, There are no local SEVEN partners near you.</option>');
  }

  $.each(regSalons, function(i, val) {
    var affiliateLink = '';
    jQuery('#woocommerce_affiliate').append('<option value="' + val[1].affiliateID + '">' + val['Salon_Name'] + '</option>');
  });
}


function compare(a, b) {
  if (a.distanceFromCenter < b.distanceFromCenter)
    return -1;
  if (a.distanceFromCenter > b.distanceFromCenter)
    return 1;
  return 0;
}

//cart scripts
$('.woocommerce-cart h1.entry-title').text('Your Shopping Bag');
